<template>
  <app-layout>
    <div class="too-sick">
      <p>
        Thank you for letting us know that you are not feeling well enough to
        record a voice sample today. Feel better soon.
      </p>

      <p>
        We will NOT share your health status with your care team. Please contact
        your physician directly if you wish to seek medical care.
      </p>

      <p>
        If you came here by mistake and are well enough to record a sample,
        <router-link :to="login">click here to start recording.</router-link>
      </p>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/appLayout";
import logVisiting from "@/mixins/logVisiting";
import { ajaxMarkTooSick } from "@/api";
import { ROUTE_LOGIN } from "@/constants";

export default {
  name: "TooSickPage",
  components: { AppLayout },
  mixins: [logVisiting],

  mounted() {
    this.markTooSick();
  },

  props: {
    token: { type: String },
  },

  data: () => ({
    login: { name: ROUTE_LOGIN },
  }),

  methods: {
    markTooSick() {
      return ajaxMarkTooSick({ token: this.token });
    },
  },
};
</script>
